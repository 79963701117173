* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    font-family: "Apercu Pro", sans-serif;
    white-space: pre-wrap;
}

@font-face {
    font-family: 'Apercu Pro';
    font-weight: 300;
    src: url('./assets/fonts/ApercuPro-Light.eot'),
    url('./assets/fonts/ApercuPro-Light.ttf'),
    url('./assets/fonts/ApercuPro-Light.svg');
}

@font-face {
    font-family: 'Apercu Pro';
    font-weight: 400;
    src: url('./assets/fonts/ApercuPro.eot'),
    url('./assets/fonts/ApercuPro.woff'),
    url('./assets/fonts/ApercuPro.ttf'),
    url('./assets/fonts/ApercuPro.svg');
}

@font-face {
    font-family: 'Apercu Pro';
    font-weight: 400;
    font-style: italic;
    src: url('./assets/fonts/ApercuPro-Italic.eot'),
    url('./assets/fonts/ApercuPro-Italic.woff'),
    url('./assets/fonts/ApercuPro-Italic.ttf'),
    url('./assets/fonts/ApercuPro-Italic.svg');
}

@font-face {
    font-family: 'Apercu Pro';
    font-weight: 500;
    src: url('./assets/fonts/ApercuPro-Medium.eot'),
    url('./assets/fonts/ApercuPro-Medium.woff'),
    url('./assets/fonts/ApercuPro-Medium.ttf'),
    url('./assets/fonts/ApercuPro-Medium.svg');
}

@font-face {
    font-family: 'Apercu Pro';
    font-weight: 500;
    font-style: italic;
    src: url('./assets/fonts/ApercuPro-MediumItalic.eot'),
    url('./assets/fonts/ApercuPro-MediumItalic.woff'),
    url('./assets/fonts/ApercuPro-MediumItalic.ttf'),
    url('./assets/fonts/ApercuPro-MediumItalic.svg');
}

@font-face {
    font-family: 'Apercu Pro';
    font-weight: 700;
    src: url('./assets/fonts/ApercuPro-Bold.eot'),
    url('./assets/fonts/ApercuPro-Bold.woff'),
    url('./assets/fonts/ApercuPro-Bold.ttf'),
    url('./assets/fonts/ApercuPro-Bold.svg');
}

@font-face {
    font-family: 'Apercu Pro';
    font-weight: 700;
    font-style: italic;
    src: url('./assets/fonts/ApercuPro-BoldItalic.eot'),
    url('./assets/fonts/ApercuPro-BoldItalic.woff'),
    url('./assets/fonts/ApercuPro-BoldItalic.ttf'),
    url('./assets/fonts/ApercuPro-BoldItalic.svg');
}

@font-face {
    font-family: 'Apercu Pro';
    font-weight: 900;
    src: url('./assets/fonts/ApercuPro-Black.woff');
}

@font-face {
    font-family: 'Apercu Pro';
    font-weight: 900;
    font-style: italic;
    src: url('./assets/fonts/ApercuPro-BlackItalic.woff');
}

body {
    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
